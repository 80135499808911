@font-face {
  font-family: "FairProsper";
  src: local("FairProsper"), url("./fonts/Fair_Prosper.ttf") format("truetype");
  font-weight: bold;
}

body {
  background-color: #fafafa;
}

.hover:hover {
  background-color: #f1f1f1;
}